export async function loadScript(source) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = source;
		document.body.appendChild(script);

		script.onload = () => {
			resolve();
		};
		script.onerror = e => {
			reject(e);
		};
	});
}
