import React, { useEffect, useState, useRef } from 'react';
import CanvasJSChart from './CanvasJSChart';
import './App.css';

function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		callback();
	}, []);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

const ranges = ['day', 'week', 'month'];

function App({ isCastMode, userId }) {
	const [myCharts, setCharts] = useState([]);

	useInterval(() => {
		const range = ranges[Math.round(Date.now() / 1000 / 60) % 3];

		fetch(`/api/data?range=${range}&id=${userId}`)
			.then(resp => {
				return resp.json();
			})
			.then(devices => {
				const charts = [];
				const chartMap = {};

				for (const { name: deviceName, dataTypes, intervalType, interval, valueFormatString } of devices) {
					for (const { name, unit, dataPoints } of dataTypes) {
						let chart = chartMap[name];

						if (!chart) {
							chart = {
								theme: 'dark2',
								legend: {
									cursor: 'pointer',
									verticalAlign: 'bottom',
									horizontalAlign: 'left',
									dockInsidePlotArea: true,
								},
								title: {
									text: name,
								},
								axisX: {
									// title: 'time',
									interval,
									intervalType,
									valueFormatString,
								},
								axisY: {
									includeZero: false,
									suffix: unit,
								},
								data: [],
							};
							charts.push(chart);
							chartMap[name] = chart;
						}
						chart.data.push({
							type: 'spline',
							showInLegend: true,
							name: deviceName,
							dataPoints: dataPoints.map(({ x, y }) => ({ x: new Date(x), y })),
						});
					}
				}

				setCharts(charts.slice(0, 4));
			});
	}, 60 * 1000);

	return (
		<div className="App">
			{myCharts.map((chart, index) => (
				<CanvasJSChart
					key={chart.title.text}
					options={chart}
					containerProps={{
						width: '50%',
						position: 'absolute',
						left: index > 1 ? '50%' : 0,
						top: index % 2 === 1 ? '50%' : 0,
						height: '50%',
					}}
				/>
			))}
			{isCastMode ? <google-cast-launcher /> : null}
		</div>
	);
}

export default App;
