import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { loadScript } from './loadScript';
import queryString from 'query-string';

// const namespace = 'urn:x-cast:dev.stagnationlab.netatmo';

let id = queryString.parse(window.location.search).id || '';
const isCastMode = window.location.pathname.includes('cast');

function render() {
	ReactDOM.render(<App key={id} isCastMode userId={id} />, document.getElementById('root'));
}

if (isCastMode) {
	loadScript('//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js')
		.then(() => {
			const cast = window.cast;
			const context = cast.framework.CastReceiverContext.getInstance();
			const options = new cast.framework.CastReceiverOptions();
			options.disableIdleTimeout = true;
			// options.customNamespaces = {
			// 	[namespace]: cast.framework.system.MessageType.JSON,
			// };
			//
			// context.addCustomMessageListener(namespace, event => {
			// 	console.log('CustomMessage: ', event);
			//
			// 	if (id !== event.data.userId) {
			// 		id = event.data.userId;
			// 		render();
			// 	}
			// });
			context.start(options);
		})
		.catch(e => {
			console.log('failed to load receiver script', e);
		});
} else {
	function initializeCastApi() {
		const framework = window.cast.framework;

		framework.CastContext.getInstance().setOptions({
			receiverApplicationId: '61B7EEEE',
		});

		// if (id) {
		// 	const context = framework.CastContext.getInstance();
		// 	context.addEventListener(framework.CastContextEventType.SESSION_STATE_CHANGED, function(event) {
		// 		if (event.sessionState === framework.SessionState.SESSION_STARTED) {
		// 			console.log('session started');
		// 			const castSession = framework.CastContext.getInstance().getCurrentSession();
		//
		// 			castSession.sendMessage(namespace, { userId: id }).then(
		// 				() => {
		// 					console.log('message sent');
		// 				},
		// 				err => {
		// 					console.log('failed to send message', err);
		// 				},
		// 			);
		// 		}
		// 	});
		// }
	}

	window['__onGCastApiAvailable'] = function(isAvailable) {
		if (isAvailable) {
			initializeCastApi();
		}
	};

	loadScript('https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1').catch(e => {
		console.log('failed to load sender script', e);
	});
}

render();
